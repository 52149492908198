<template>
    <div style="position:relative;width: 100%;overflow: hidden;min-height: 100vh">
        <div class="header-row">
            <div class="heade-div">
                <div class="logo" @click="$router.push('/')">
                    <img style="height: 60px;margin-right: 12px" :src="preFix + appInfo.pc_logo">
<!--                    <p class="title">{{appInfo.app_name}}</p>-->
                </div>
                <div class="disCenter height100">
                    <div class="homePage"  style="margin-right: 50px;cursor: pointer;" @click="$router.push('/')">
                        <img src="../assets/homeIcon.png" style="width: 22px;height: 21px;margin-right: 10px" alt="">
                        主页
                    </div>
                    <!--                   <div class="down" :class="$route.path == '/merchant' ? 'homePage1' : ''" style="cursor:pointer;" @click="$router.push('/merchant')">-->
                    <!--                        APP下载-->
                    <!--                   </div>-->
                </div>
            </div>
        </div>
        <div style="overflow: hidden;margin-bottom: -10px;">
            <img class="bgc" src="../assets/login-bgc.jpg" alt="">

            <div class="code">
                <span class="code-text">扫码下载APP</span>
                <div class="code-view">
                    <img class="code-img" :src="preFix + appInfo.download_ewm">
                </div>
            </div>
        </div>
        <div class="main-business" id="mainBusiness">
           <div class="business bgAA ">
               <p class="headline1">Main Business</p>
               <div class="dis">
                   <div style="width: 426px;height: 2px; background-color:#fff;"></div>
                   <div style="width: 21px;height: 21px; background-color:#fff;border-radius: 50%;margin-left: 10px"></div>
                   <h3 class="headline1-title ">主营业务</h3>
                   <div style="width: 21px;height: 21px; background-color:#fff;border-radius: 50%;margin-right: 10px"></div>
                   <div style="width: 426px;height: 2px; background-color:#fff;"></div>
               </div>
               <p class="headline2">实力承接短视频制作和分发短视频 <span style="color: #FFE400">服务</span>，万人矩阵带货</p>
            <div class="borColorAA"></div>
           </div>
<!--            <div class="flow">-->
<!--                <div class="flow-item" style="justify-content: center">-->
<!--                    <div class="flow-item-tit">-->
<!--                        <p>短视频</p>-->
<!--                        <p>推广</p>-->
<!--                    </div>-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/sjfb.png" alt="">-->
<!--                                <p>商家上传视频</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/shtg.png" alt="">-->
<!--                                <p>平台审核通过</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/drjd.png" alt="">-->
<!--                                <p>达人接单</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/drff.png" alt="">-->
<!--                                <p>达人分发视频</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->

<!--                <div class="flow-item" style="justify-content: center">-->
<!--                    <div class="flow-item-tit">-->
<!--                        <p>短视频</p>-->
<!--                        <p>制作</p>-->
<!--                    </div>-->
<!--                    <ul>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/sjfb.png" alt="">-->
<!--                                <p>商家发布视频任务</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/pkjd.png" alt="">-->
<!--                                <p>拍客接单</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/yyjyp.png" alt="">-->
<!--                                <p>商家邮寄样品</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/wcsp.png" alt="">-->
<!--                                <p>拍客完成视频</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--                <div class="flow-item" style="margin-left: 75px">-->
<!--                    <ul class="item-ul">-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/qrff.png" alt="">-->
<!--                                <p>商家确认视频分发</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/drjd.png" alt="">-->
<!--                                <p>达人接单</p>-->
<!--                            </div>-->
<!--                        </li>-->
<!--                        <li class="item">-->
<!--                            <div class="flow-item">-->
<!--                                <img src="../assets/icon/drff.png" alt="">-->
<!--                                <p>达人分发视频</p>-->
<!--                            </div>-->
<!--                        </li>-->

<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
          <div class="swiper-div">
              <el-carousel  arrow="always" class="swiper" indicator-position="none" :initial-index="inerIndex" @change="change" :interval="2000">
                  <el-carousel-item class="swiper-view" v-for="(item,index) in bannerList" :key="item">
                      <div class="swiper-item">
                          <!--                        <img class="swiper-item" :src="index == 1 ? '../assets/banner1.jpg' : index == 2 ? '../assets/icon/bannerTree.jpg' :'../assets/icon/bannerTwo.jpg' " alt="">-->
                          <img src="../assets/icon/bannerTree.jpg" v-if="index == 0">
                          <img src="../assets/icon/bannerTwo.jpg" v-if="index == 2">
                          <img src="../assets/icon/bannerOne.jpg" v-if="index == 1">
                      </div>
                  </el-carousel-item>
                  <div class="list">
                      <div class="iner" v-for="(item,index) in bannerList" :class="index == swiperIndex ? 'active' : 'noActive'" @click="inerFn(index)"></div>
                  </div>
              </el-carousel>
          </div>

        </div>
<!--        <div class="statistics">-->
<!--            <div class="statistics-item">-->
<!--                <img src="../assets/icon/jrjr.png" alt="">-->
<!--                <div class="num">-->
<!--                    <p>{{pcDataList.today_user}}<span>人</span></p>-->
<!--                    <p>今日加入达人</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="statistics-item">-->
<!--                <img src="../assets/icon/lmwz.png" alt="">-->
<!--                <div class="num">-->
<!--                    <p>{{pcDataList.proxy}}<span>个</span></p>-->
<!--                    <p>联盟网站</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="statistics-item">-->
<!--                <img src="../assets/icon/ptzsh.png" alt="">-->
<!--                <div class="num">-->
<!--                    <p>{{pcDataList.sj_user}} <span>人</span></p>-->
<!--                    <p>平台总商户</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="statistics-item">-->
<!--                <img src="../assets/icon/ptapk.png" alt="">-->
<!--                <div class="num">-->
<!--                    <p>{{pcDataList.pk_user}} <span>人</span></p>-->
<!--                    <p>平台总拍客</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="statistics-item">-->
<!--                <img src="../assets/icon/ptzdr.png" alt="">-->
<!--                <div class="num">-->
<!--                    <p>{{pcDataList.dr_user}} <span>人</span></p>-->
<!--                    <p>平台总达人</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="regard">-->
<!--            <h3>关于我们</h3>-->
<!--            <p>About Us</p>-->
<!--            <span>-->
<!--                平台整合各大电商平台店主，商家提供优质货源，专业的拍客提供视频持续输出-->
<!--                <br>-->
<!--                同时公司招募上万专业短视频达人参与视频分发到短视频平台，可直接帮商家减少验货商品成本和快递成本。目前旗下已聚集达人超10000位服-->
<!--                <br>-->
<!--                务于品牌吸商家超500家、并在不断提升中目前覆盖的短视频社交平合包含短视频，快手，小红书等-->
<!--            </span>-->
<!--            <div style="position:relative;height: 480px;margin-top: 100px">-->
<!--                <img class="dian-img" src="../assets/diann.png" alt="">-->
<!--                <img class="screen-img" src="../assets/screen.png" alt="">-->
<!--            </div>-->

<!--        </div>-->
        <div class="business bg00A8FF">
            <p class="headline1">About Us</p>
            <div class="dis">
                <div style="width: 426px;height: 2px; background-color:#fff;"></div>
                <div style="width: 21px;height: 21px; background-color:#fff;border-radius: 50%;margin-left: 10px"></div>
                <h3 class="headline1-title ">关于我们</h3>
                <div style="width: 21px;height: 21px; background-color:#fff;border-radius: 50%;margin-right: 10px"></div>
                <div style="width: 426px;height: 2px; background-color:#fff;"></div>
            </div>
            <p class="headline2">一个样品打造 <span style="color: #FFE400">爆款</span>，合作 <span style="color: #FFE400">爆款</span>，新思维 新策略</p>
            <div class="borColor00A8"></div>
        </div>
        <div class="business-content">
            <div class="regard" @click="show = true">
                <div class="regard1"></div>
                <div class="content-img">
                    <div class="content-img-layer"></div>
                    <div class="content-icon"></div>
                    <img class="content-img" src="../assets/sj/sj_video_img.jpg" alt="">
                </div>
                <div class="regard2"></div>
            </div>

            <p class="content-p"> 专业的拍客提供视频
                持续输出同时公司招募上万专业短视频达人参与视频分发到短视频平台
                可直接帮商家减少验货商品成本和快递成本。<br>
                目前旗下已聚集达人超10000位服务于品牌吸商家超500家、并在不断
                提升中目前覆盖的短视频社交平合包含短视频，快手，小红书等。
            </p>
            <div class="sanjiao"></div>
        </div>
        <div class="tarGet">
            <div class="tarGet-view">
                <img src="../assets/bgcIcon.png">
            </div>
            <div class="tarGetList" >
                <div class="tarGetList-item" v-for="(item,index) in tarGetList">
                    <p class="num">{{item.num}}</p>
                    <p class="text">{{item.text}}</p>
                </div>
            </div>
        </div>
<!--        <div style="height: 128px;">-->
<!--            <img class="footer-img" src="../assets/bot.png" alt="">-->
<!--            <img src="../assets/icon/bannerTree.jpg" alt="">-->
<!--        </div>-->
        <div class="" @mousewheel="mousewheel" :class="show ? 'layer' : 'noLayer'"  v-if="show" @click.top="show = false">
            <div class="video_view" >
                <video :src="video_src" autoplay></video>
            </div>
        </div>
        <video :src="video_src" autoplay style="display: none"></video>
    </div>
</template>

<script>
    import Swiper from "swiper"
    import api from '../lnit/api'
    export default {
        name: "merchant",
        data(){
            return{
                show:false,
                video_src:'',
                bannerList:[
                    {
                        src:''
                    },
                    {
                        src:'../assets/icon/bannerTwo.jpg'
                    },
                    {
                        src:'../assets/icon/bannerTree.jpg'
                    }
                ],
                tarGetList:[
                    {
                        num:'837人',
                        text:'总加入达人'
                    },
                    {
                        num:'837人',
                        text:'总商家人数'
                    },
                    {
                        num:'837人',
                        text:'今日加入商家'
                    },
                    {
                        num:'837人',
                        text:'今日加入拍客'
                    },
                    {
                        num:'837人',
                        text:'总代理人数'
                    }
                ],
                pcDataList:null,
                DataList:null,
                preFix:null,
                url_prefix:'',
                swiperIndex:0,
                inerIndex:0,
                appInfo:{},
            }
        },
        mounted() {
            this.getAppInfoFn()
        },
        created() {
            this.pcDataFn()
            this.preFix = api.url_prefix
            this.video_src = api.url_prefix + api.video_src
            console.log(this.video_src)
        },
        methods:{
            mousewheel(e){
                e.preventDefault();
            },
            inerFn(index){
                this.inerIndex = index
                console.log(this.inerIndex)
            },
            change(e){
                this.swiperIndex = e
            },
            getAppInfoFn(){
                this.$get({
                    url:api.app_info
                }).then((res)=>{
                    this.appInfo = res.data.app_info
                })
            },
            pcDataFn(){
                this.$get({
                    url:api.pc_data
                }).then((res)=>{
                    this.tarGetList[0].num = res.data.data.dr_user + '人'
                    this.tarGetList[3].num = res.data.data.pk_user+ '人'
                    this.tarGetList[4].num = res.data.data.proxy+ '人'
                    this.tarGetList[2].num = res.data.data.sj_user+ '人'
                    this.tarGetList[1].num = res.data.data.total_user+ '人'
                    this.pcDataList = res.data.data
                })
                this.$get({
                    url:api.contact
                }).then((res)=>{
                    this.DataList = res.data.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">

    @import url("../../node_modules/swiper/css/swiper.css");
    .swiper-div{
        width: 100%;
        height: 580px;
        position: relative;
    }
    .layer{
        position: fixed;
        top: 0;
        left: 0%;
        width: 100vw;
        z-index:10000;
        height: 100vh;
        background-color: rgba(0,0,0,0.4);
        animation: layers 0.5s;
    }
    @keyframes layers {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    .noLayer{
        animation: nolayer 0.5s;
    }
    @keyframes nolayer {
        from{
            opacity: 1;
        }
        to{
            opacity: 0;
        }
    }
    .video_view{
        width: 60%;
        height: 70%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        video{
            width: 100%;
            height: 100%;
        }
    }
    .swiper{
        width: 100%;
        height: 580px;
        .swiper-view{
            width: 100%;
            height: 580px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            .swiper-item{
                width: 1200px;
                height: 360px;
                img{
                    width: 1200px;
                    height: 300px;
                }
            }
        }
    }
    .list{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        bottom: -200px;
        .iner{
            width: 23px;
            height: 23px;

            border-radius: 50%;
            margin: 0 30px;
        }
    }
    .active{
        background-color: #707070;
    }
    .noActive{
        background-color: rgba(112,112,112,0.36);
    }
    .disCenter{
        display: flex;
        align-items: center;
    }
    .bgc{
        min-width: 1200px;
        height: 827px;
        width: 100%;
    }
    .code{
        position: absolute;
        top: 207px;
        right: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tarGetList{
        width: 1200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 200px 0 210px;
        box-sizing: border-box;
    }
    .tarGetList-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        .num{
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
        }
    }
    .code-text{
        color: white;
    }
    .code-view {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        width: 180px;
        height: 180px;
        background: #FFFFFF;
        /*border-radius: 30px;*/
        margin-top: 13px;
        .code-img{
            width: 172px;
            height: 172px;
        }
    }
    .main-business {
        width: 100%;
        background-color: #F9FAFC;
    }
    .business{
        position: relative;
        width: 100%;
        height: 262px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bgAA{
        background-color: #AAAAAA;
    }
    .bg00A8FF{
        background-color: #00A8FF;
    }
    .borColorAA{
        width: 0px;
        height: 0px;
        border: 30px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -60px;
        z-index:100;
        transform: translateX(-50%);
        border-top-color: #AAAAAA;
    }
    .borColor00A8{
        width: 0px;
        height: 0px;
        border: 30px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -60px;
        z-index:100;
        transform: translateX(-50%);
        border-top-color: #00A8FF;
    }
    .sanjiao{
        width: 0px;
        height: 0px;
        border: 30px solid transparent;
        position: absolute;
        left: 50%;
        bottom: -60px;
        z-index:100;
        transform: translateX(-50%);
        border-top-color: #ffffff;
    }
    .business-content{
        width: 1200px;
        padding: 60px 0 45px 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;
    }
    .tarGet{
        width: 100%;
        height: 371px;
        background: #CBCBCB;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        .tarGet-view{
            width: 1270px;
            margin-bottom: 15px;
        }
        img{
            width: 1270px;
            height: 139px;
        }
    }
    .content-p{
        flex: 1;
        margin-left: 62px;
        color: #707070;
        font-size: 26px;
        text-indent: 50px;
    }
    .regard{
        position: relative;
        width: 356px;
        height: 348px;
        display: flex;
        align-items: center;
        justify-content: center;
        .content-img{
            position: relative;
            width: 333px;
            height: 324px;
            cursor: pointer;
        }
        .content-img-layer{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.4);
            position: absolute;
            z-index:101;
        }
        .content-icon{
            width: 100px;
            height: 100px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index:102;
            background: url("../assets/sj/video_icon.png") no-repeat;
            background-size: cover;
        }
        img{
            position: absolute;
            left:50%;
            top: 50%;
            transform: translate(-50%,-50%);
            z-index:100;
            width: 333px;
            height: 324px;
        }
    }
    .regard1{
        width: 110px;
        height: 115px;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #FFBA00;
    }
    .regard2{
        width: 110px;
        height: 115px;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: #FFBA00;
    }
    .main-business>h3 {
        color: #000000;
        font-size: 36px;
        font-weight: 800;
        text-align: center;
    }
    .headline1 {
        color: #ffffff;
        font-size: 30px;
        text-align: center;
        font-family: Bahnschrift;
    }
    .headline1-title{
        color: #FFE400;
        font-size: 36px;
        font-family: Microsoft YaHei;
        margin: 0 21px 0 28px;
        font-weight: bold;
    }
    .homePage{
        position: relative;
        color: white;
        height: 100%;
        font-size: 20px;
        display: flex;
        align-items: center;
        line-height: 20px;
    }
    .homePage1::after{
        content: '';
        width: 60px;
        height: 8px;
        background: #FFFFFF;
        border-radius: 5px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translateX(-50%);
    }
    .headline2 {
        color: #ffffff;
        font-size: 26px;
        text-align: center;
        font-family: Microsoft YaHei;
    }
    .main-business>.flow {
        width: 1260px;
        margin: 0 auto;
    }
    .dis {
        display:  flex;
        align-items: center;
        margin: 17px 0 29px 0;
    }
    .logo{
        cursor: pointer;
        display: flex;
        align-items: center;
        img{
            height: 40px!important;
        }
    }
    .main-business>.flow>.flow-item {
        display: flex;
         /*justify-content: center;*/
        align-items: center;
        img{
            width: 50px;
            height: 50px;
        }

    }
    .main-business>.flow>.flow-item .flow-item-tit {
        width: 140px;
        height: 140px;
        box-shadow: 0px 15px 22px 0px rgba(0, 0, 0,.1);
        border-radius: 16px;
        margin-right: 80px;
        margin-bottom: 60px;
        background-color: #fff;
        text-align: center;
        padding: 38px 0;
        box-sizing: border-box;
        color: #222222;
        font-size: 20px;
        position: relative;
    }
    .main-business>.flow>.flow-item .flow-item-tit::before {
        content: '';
        background-image: url(../assets/arr-right.png);
        background-size: cover;
        width: 42px;
        height: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -60px;
    }
    .main-business>.flow>.flow-item .flow-item-tit p {
        line-height: 36px;
        margin: 0;
    }
    .main-business>.flow .flow-item ul {
        display: flex;
        padding: 0;
    }
    .main-business>.flow .flow-item ul li {
        width: 140px;
        height: 140px;
        text-align: center;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px 15px 22px 0px rgba(0, 0 ,0 ,.1);
        box-sizing: border-box;
        padding-top: 32px;
        font-size: 14px;
        margin-right: 80px;
        margin-bottom: 60px;
        position: relative;
        &:nth-child(4):before{
            content: '';
            background: none;
        }
    }
    .item::before{
        content: '';
        background-image: none!important;
    }
    .main-business>.flow .flow-item ul li::before {
        content: '';
        background-image: url(../assets/arr-right.png);
        background-size: cover;
        width: 42px;
        height: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -60px;
    }
    li {
        list-style-image: none;
        list-style-position: outside;
        list-style-type: none;
    }
    .statistics {
        width: 100%;
        height: 360px;
        background-color: #08244C;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        padding-top: 126px;
        box-sizing: border-box;
        /*background-image: url(../img/tj-bgi.png);*/
        background-size: cover;
    }
    .statistics .statistics-item {
        display: flex;
        align-items: center;
        color: #fff;
        height: 72px;
        margin-right: 80px;
    }
    .statistics .statistics-item>img {
        width: 72px;
        height: 72px;
        margin-right: 16px;
    }
    statistics .statistics-item>.num p:first-child {
        font-size: 32px;
        line-height: 45px;
    }
    .statistics .statistics-item>.num p:last-child {
        opacity: 0.6;
        font-size: 20px;
    }
    .num{
        font-size: 24px;
        p{
            margin:5px 0;
        }
    }
    .header-row{
        position: absolute;
        top: 0;
        color: #fff;
        left: 0;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        /*padding: 0 120px;*/
        box-sizing: border-box;
        background-color: transparent;
        z-index: 100;
        border-bottom: 1px solid rgba(255,255,255,0.6);
        .heade-div{
            width: 1200px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .regard{
        /*width: 100%;*/
        /*text-align: center;*/
        /*margin-bottom: 40px;*/
        /*min-height: 200px;*/
        h3{
            /*font-size: 36px;*/
            /*margin-bottom: 10px;*/
        }
        p{
            /*font-size: 24px;*/
            /*margin-top: 0;*/
        }
        span{
            /*line-height: 30px;*/
            /*color: #999999;*/
            /*display: block;*/
        }
        .dian-img{
            /*position: absolute;*/
            /*width: 770px;*/
            /*left: 50%;*/
        }
        .screen-img{
            /*position: absolute;*/
            /*width: 586px;*/
            /*height: 368px;*/
            /*top: 25px;*/
            /*left: 50%;*/
        }
    }
    .footer-img{
        width: 100%;
        height: 100%;
    }
    .swiper-container {
        width: 100%;
        height: 9.375rem;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        width: 100%;
        height: 100%;
    }
</style>
